import React from 'react';

const MainDetails = () => {



  // const {name,address,email} = props;
  return (
    <div>
      <section className="flex items-end justify-end flex-col border-b-2 border-black">
          <h2 className="text-3xl font-bold uppercase ">CRUMBELL</h2>
          <p>Avantika Road
          Jamalpur,Bihar, 811214</p>
          <p>crumbellco@gmail.com</p>
      </section>
    </div>
  );
}

export default MainDetails;
