import React from 'react';

const InvoiceTable = ({ data }) => {
    const taxRate = 0.12; // 12% tax rate

    // Calculate subtotal
    const subtotal = data?.order?.items.reduce((total, product) => total + (product.quantity * product.pricePerUnit), 0);

    // Calculate GST amount based on subtotal
    const gstAmount = subtotal * taxRate;

    // Calculate total amount including GST
    const totalAmount = subtotal + gstAmount;

    // Calculate total price for each product with a 12% reduction
    const calculateReducedPrice = (price) => price - (price * 0.12);

    // Function to format currency in INR
    const formatCurrency = (amount) => {
        return `₹${amount.toFixed(2)}`;
    };

    return (
        <div className="bg-white overflow-x-auto">
            <table className="w-full border-collapse">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="border px-4 py-2 text-left">Product Name</th>
                        <th className="border px-4 py-2 text-left">Quantity</th>
                        <th className="border px-4 py-2 text-left">Unit Price</th>
                        <th className="border px-4 py-2 text-left">Total Price</th>
                    </tr>
                </thead>
                
                <tbody>
                    {data?.order?.items.map((product, index) => {
                        const totalPrice = product.quantity * product.pricePerUnit;
                        const reducedTotalPrice = calculateReducedPrice(totalPrice);

                        return (
                            <tr key={index}>
                                <td className="border px-4 py-2">{product.itemName}</td>
                                <td className="border px-4 py-2 text-center">{product.quantity}</td>
                                <td className="border px-4 py-2 text-right">{formatCurrency(product.pricePerUnit)}</td>
                                <td className="border px-4 py-2 text-right">
                                    {formatCurrency(reducedTotalPrice)}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="3" className="border px-4 py-2 text-right font-semibold">GST (12%)</td>
                        <td className="border px-4 py-2 text-right font-semibold">
                            {formatCurrency(gstAmount)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" className="border px-4 py-2 text-right font-bold">Total Amount</td>
                        <td className="border px-4 py-2 text-right font-bold">
                            {data?.order?.totalAmount}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default InvoiceTable;
