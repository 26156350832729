import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import InvoicePage from './components/InvoicePage';
import TopHeader from './components/TopHeader';

function App() {
  return (
    <Router>
      <main className="m-5 p-5 xl:max-w-4xl xl:mx-auto bg-white rounded shadow">
        <Routes>
          {/* <Route path="/" element={<TopHeader/>} /> */}
          <Route path="/" element={<InvoicePage />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
