import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Header from "./Header";
import MainDetails from "./MainDetails";
import ClientsDetails from "./ClientsDetails";
import Dates from "./Dates";
import Table from "./Table";
import Notes from "./Notes";
import Footer from "./Footer";
import TopHeader from './TopHeader';

function InvoicePage() {
    const [SearchPArams] = useSearchParams();
    console.log('idididididid',SearchPArams.get("id"))
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchManagers = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://backend.crumbell.in/api/v1/order/${SearchPArams.get("id")}/invoices`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            console.log('Fetched data:', data);
            setInvoiceDetails(data); // Update the state with the fetched data
        } catch (error) {
            console.error('Error fetching managers:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (SearchPArams.get("id")) {
            fetchManagers();
        }
    }, [SearchPArams.get("id")]);

    if (loading) return <p style={{textAlign:'center'}}>Loading...</p>;

    return (
        <main className="m-5 p-5 xl:max-w-4xl xl:mx-auto bg-white rounded shadow">
            <div>
                <TopHeader/>
                <Header />
                <MainDetails data={invoiceDetails} />
                <ClientsDetails data={invoiceDetails} />
                <Dates data={invoiceDetails} />
                <Table data={invoiceDetails} />
                <Notes data={invoiceDetails} />
                <Footer data={invoiceDetails} />
            </div>
        </main>
    );
}

export default InvoicePage;
