import React from 'react';

const notes = () => {
  return (
    <div>
      <section className="mb-5">
        {/* text area */}
        <p className="my-5 ">Thank you for your purchase! For any queries or need further assistance, please feel free to contact us.</p>
      
      </section>
    </div>
  );
}

export default notes;
