import React from 'react';

const ClientsDetails = ({data}) => {
  console.log('datadatadatadata',data)
  return (
    <div>
      <section className="mt-2 ">
          <h2 className="text-xl font-bold uppercase">Client/shop Details</h2>
          <p><strong>Name :</strong> {data?.order?.shop?.name}</p>
          <p><strong>Address :</strong> {data?.order?.shop?.area}</p>
          <p><strong>Phone :</strong> {data?.order?.shop?.contactNumber}</p>
          {/* <p><strong>Email :</strong> manishraj11@gmail.com</p> */}
          
      </section>
    </div>
  );
}

export default ClientsDetails;
