import React from 'react';

const Dates = ({data}) => {
  const formatCurrency = (amount) => {
    return `₹${amount.toFixed(2)}`;
};
  return (
    <div>
    <article className="my-1 flex justify-end items-end  ">
        <ul>
          <li><span className="font-bold ">Order ID:</span> {data?.order?.orderId}</li>
          <li><span className="font-bold ">Order date:</span> {data?.order?.orderDate.split('T')[0]}</li>
          <li><span className="font-bold ">Paid amount:</span> {data?.order?.amountPaid}</li>
          <li><span className="font-bold ">Payment Status:</span> {data?.order?.paymentStatus}</li>
        </ul>
      </article>
      
        <div style={{marginTop:20,marginBottom:20}} className="bg-white overflow-x-auto">
            <table className="w-full border-collapse">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="border px-4 py-2 text-left">Transaction ID</th>
                        <th className="border px-4 py-2 text-left">Amount</th>
                        <th className="border px-4 py-2 text-left">Collected by</th>
                        <th className="border px-4 py-2 text-left">Payment Date</th>
                    </tr>
                </thead>

                <tbody>
                    {data?.payments.map((payment, index) => (
                        <tr key={payment.id}>
                            <td className="border px-4 py-2">{payment.transactionId}</td>
                            <td className="border px-4 py-2 text-right">{formatCurrency(payment.amount)}</td>
                            <td className="border px-4 py-2">{payment.createdBy?.name}</td>
                            <td className="border px-4 py-2">{payment.paymentDate.split('T')[0]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
  );
}

export default Dates;
