import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const Header = () => {
    const [isPrinting, setIsPrinting] = useState(false);
    const [SearchPArams] = useSearchParams();
    // Function to handle print functionality
    const handlePrint = () => {
        // Set isPrinting to true to hide buttons
        setIsPrinting(true);

        // Create a style element for print styles
        const printStyle = document.createElement('style');
        printStyle.type = 'text/css';
        printStyle.media = 'print';
        printStyle.innerHTML = `
            * {
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
            }
            header {
                display: block;
            }
        `;
    
        // Append style to head
        document.head.appendChild(printStyle);

        // Use setTimeout to ensure the state update takes effect before printing
        setTimeout(() => {
            window.print();
            // Remove the print style and reset state after a short delay
            setTimeout(() => {
                document.head.removeChild(printStyle);
                setIsPrinting(false);
            }, 100);
        }, 100);
    };

    // Function to handle download functionality
    // (implementation omitted, as it's not provided)

    // Function to handle share functionality
    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Invoice',
                    text: 'Check out this invoice!',
                    url: `https://invoice.crumbell.in?id=${SearchPArams.get("id")}` // URL to share
                });
                console.log('Share was successful.');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            // Fallback for browsers that do not support the Web Share API
            navigator.clipboard.writeText(`https://invoice.crumbell.in?id=${SearchPArams.get("id")}`)
                .then(() => {
                    alert('Invoice link copied to clipboard!');
                })
                .catch(err => {
                    console.error('Failed to copy:', err);
                });
        }
    };

    return (
        <div>
            <header
                className={`flex flex-col justify-center items-center mb-5 lg:flex-row lg:justify-between lg:items-center px-4 py-6 bg-gray-100 shadow-md rounded-lg ${isPrinting ? 'hidden' : ''}`}
            >
                <h1 className="font-bold uppercase tracking-wide text-4xl mb-3 lg:mb-0 text-gray-800">
                    Invoice
                </h1>

                <div>
                    <ul className="flex items-center gap-4 justify-between flex-wrap">
                        <li>
                            <button
                                onClick={handlePrint}
                                className="px-4 py-1 text-white bg-blue-600 hover:bg-blue-700 rounded transition duration-300 ease-in-out"
                            >
                                Print
                            </button>
                        </li>
                     
                        <li>
                            <button
                                onClick={handleShare}
                                className="px-4 py-1 text-white bg-slate-500 hover:bg-slate-700 rounded transition duration-300 ease-in-out"
                            >
                                Share
                            </button>
                        </li>
                    </ul>
                </div>
            </header>
        </div>
    );
};

export default Header;
